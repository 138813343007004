<template>
  <Table hoverable scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('hunts.hunt')" />
      <TableHeaderCell :heading="$t('hunts.date')" />
      <TableHeaderCell />
      <TableHeaderCell />
    </TableHeader>

    <TableContent>
      <HuntsTableRow v-for="hunt in hunts" :key="hunt.id" :hunt="hunt" />

      <TableRow v-if="hunts.length === 0">
        <TableCell colspan="4" class="has-text-grey">
          {{ $t('hunts.noHunts') }}
        </TableCell>
      </TableRow>
    </TableContent>
  </Table>
</template>

<script>
import HuntsTableRow from './HuntsTableRow.vue'

export default {
  components: {
    HuntsTableRow
  },

  props: {
    hunts: {
      type: Array,
      default: () => []
    }
  }
}
</script>
