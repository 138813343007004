<template>
  <Wrapper>
    <Titlebar :title="$t('hunts.hunts')" />

    <div class="default-spacing">
      <div class="columns hunts-columns">
        <div class="column is-half">
          <Heading size="5" :text="$t('hunts.reports')" />

          <Toolbar>
            <ToolbarItemGroup align="left">
              <ToolbarItem>
                <Button
                  type="primary"
                  :title="$t('hunts.newReport')"
                  @click="createReport">
                </Button>
              </ToolbarItem>

              <ToolbarItem>
                <Button
                  type="secondary"
                  :title="$t('hunts.exportToExcel')"
                  :disabled="!canExport"
                  :loading="exporting"
                  @click="downloadAsExcel">
                </Button>
              </ToolbarItem>
            </ToolbarItemGroup>
          </Toolbar>

          <InlineLoader v-if="reports === null" :dark="true" />

          <ReportsTable v-else :reports="reports" />
        </div>

        <div class="column is-half">
          <Heading size="5" :text="$t('hunts.plannedHunts')" />

          <Toolbar>
            <ToolbarItemGroup align="left">
              <ToolbarItem>
                <HuntsPlanHuntButton />
              </ToolbarItem>
            </ToolbarItemGroup>
          </Toolbar>

          <InlineLoader v-if="hunts === null" :dark="true" />

          <HuntsTable v-else :hunts="hunts" />
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

import Titlebar from '@/modules/core/components/layout/Titlebar.vue'

import HuntsPlanHuntButton from './HuntsPlanHuntButton.vue'
import ReportsTable from './ReportsTable.vue'
import HuntsTable from './HuntsTable.vue'

import { HUNT_STATUS_PLANNED, HUNT_STATUS_PLANNING } from '../../../../api/hunt/huntModel'

export default {
  components: {
    Titlebar,
    HuntsPlanHuntButton,
    ReportsTable,
    HuntsTable
  },

  data () {
    return {
      exporting: false
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected'
    }),

    isLoading () {
      return this.reports === null || this.hunts === null
    },

    canExport () {
      return this.reports !== null && this.reports.length > 0
    },

    allHunts () {
      const hunts = this.$store.getters['hunt/hunts']
      return hunts !== null ? hunts.filter(hunt => hunt.huntAreaId === this.huntArea.id) : null
    },

    reports () {
      const reports = this.$store.getters['huntarea/reports/getAll']
      return reports !== null ? reports.slice().sort(this.sortByStartDateDescending) : null
    },

    hunts () {
      if (this.allHunts !== null) {
        let planningHunts = this.allHunts.filter(hunt => hunt.status === HUNT_STATUS_PLANNING)
        planningHunts = planningHunts.slice().sort(this.sortByStartDateAscending)

        let plannedHunts = this.allHunts.filter(hunt => hunt.status === HUNT_STATUS_PLANNED)
        plannedHunts = plannedHunts.slice().sort(this.sortByStartDateAscending)

        return [...planningHunts, ...plannedHunts]
      }

      return null
    }
  },

  methods: {
    sortByStartDateAscending (a, b) {
      return this.$dayjs(a.startsAt).toDate() - this.$dayjs(b.startsAt).toDate()
    },

    sortByStartDateDescending (a, b) {
      return this.$dayjs(b.startsAt).toDate() - this.$dayjs(a.startsAt).toDate()
    },

    createReport () {
      this.$router.push({ name: 'submit-report' })
    },

    async downloadAsExcel () {
      this.exporting = true

      try {
        await this.$store.dispatch('huntarea/reports/downloadAsExcel')
      } catch (error) {
        this.$notification.danger(this.$i18n.t('report.exportFailedErrorMessage'))
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.default-spacing {
  padding-top: 0 !important;
}

@media screen and (max-width: 769px) {
  .hunts-columns {
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
