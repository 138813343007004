<template>
  <div class="upgrade-subscription-button-container">
    <Button
      type="primary"
      :loading="submitting"
      :disabled="!hasPro"
      @click="planHunt">
      <Icon v-if="showRequiresProBadge" name="icon-lock" />
      {{ $t('hunt.planHunt') }}
    </Button>

    <UpgradeSubscriptionBadge />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UpgradeSubscriptionBadge from '@/modules/subscription/components/UpgradeSubscriptionBadge.vue'

export default {
  components: {
    UpgradeSubscriptionBadge
  },

  data () {
    return {
      submitting: false
    }
  },

  computed: {
    ...mapGetters({
      huntArea: 'huntarea/selected',
      hasPro: 'subscription/hasPro',
      isLoadingSubscription: 'subscription/isLoading'
    }),

    showRequiresProBadge () {
      return !this.hasPro && !this.isLoadingSubscription
    }
  },

  methods: {
    async planHunt () {
      this.submitting = true

      try {
        const hunt = await this.$store.dispatch('hunt/create', {
          name: this.huntArea.name,
          huntAreaId: this.huntArea.id
        })

        this.$router.push(`/hunt/${hunt.id}/`)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.planHuntFailedErrorMessage'))
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button .icon {
  margin-right: 0.25rem !important;
}

.upgrade-subscription-button-container {
  display: inline-block;
  position: relative;

  .upgrade-subscription-badge {
    position: absolute;
    top: -15px;
    right: -30px;
  }
}
</style>
