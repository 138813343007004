<template>
  <TableRow class="is-clickable" @click="viewReport">
    <TableCell>
      {{ title }}
    </TableCell>

    <TableCell>
      {{ date }}
    </TableCell>

    <TableCell>
      <Icon name="icon-crosshair" /> {{ kills }}
      <Icon name="icon-eye" /> {{ observations }}
    </TableCell>

    <TableCell>
      <Icon name="icon-chevron-right is-pulled-right" />
    </TableCell>
  </TableRow>
</template>

<script>
import { HUNT_REPORT_EVENT_TYPE_OBSERVATION } from '@/modules/huntArea/report/models/huntReportEventModel'

export default {
  props: {
    report: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      kills: 0,
      observations: 0
    }
  },

  computed: {
    date () {
      return this.report.startsAt ? this.$dayjs(this.report.startsAt).format('D MMMM YYYY') : '-'
    },

    title () {
      return this.report.title ? this.report.title : this.$i18n.t('report.huntingReportDefaultTitle', { date: this.$dayjs(this.report.startsAt).format('D MMMM') })
    }
  },

  created () {
    this.calculateStatistics()
  },

  methods: {
    calculateStatistics () {
      this.report.events.forEach(event => {
        event.games.forEach(game => {
          if (event.type === HUNT_REPORT_EVENT_TYPE_OBSERVATION) {
            this.observations += game.count
          } else {
            this.kills += game.count
          }
        })
      })
    },

    viewReport () {
      this.$router.push({
        name: 'view-report-general-tab',
        params: { reportId: this.report.id },
        query: { from: this.$route.path }
      })
    }
  }
}
</script>
