<template>
  <Table hoverable scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('hunts.hunt')" />
      <TableHeaderCell :heading="$t('hunts.date')" />
      <TableHeaderCell :heading="$t('hunts.killsAndObservations')" />
      <TableHeaderCell />
    </TableHeader>

    <TableContent>
      <ReportsTableRow v-for="report in reports" :key="report.id" :report="report" />

      <TableRow v-if="reports.length === 0">
        <TableCell colspan="4" class="has-text-grey">
          {{ $t('hunts.noReports') }}
        </TableCell>
      </TableRow>
    </TableContent>
  </Table>
</template>

<script>
import ReportsTableRow from './ReportsTableRow.vue'

export default {
  components: {
    ReportsTableRow
  },

  props: {
    reports: {
      type: Array,
      default: () => []
    }
  }
}
</script>
