<template>
  <TableRow class="is-clickable" @click="openHunt">
    <TableCell style="position: relative;">
      <div v-if="isPlanning" class="planning" />
      {{ hunt.name }}
    </TableCell>

    <TableCell>
      <span v-if="hunt.startsAt !== null">
        {{ date }}
      </span>
    </TableCell>

    <TableCell>
      {{ status }}
    </TableCell>

    <TableCell>
      <Icon name="icon-chevron-right" class="is-pulled-right" />
    </TableCell>
  </TableRow>
</template>

<script>
import { HUNT_STATUS_PLANNING } from '@/api/hunt/huntModel'

export default {
  props: {
    hunt: {
      type: Object,
      required: true
    }
  },

  computed: {
    date () {
      return this.$dayjs(this.hunt.startsAt).format('D MMMM YYYY')
    },

    isPlanning () {
      return this.hunt.status === HUNT_STATUS_PLANNING
    },

    status () {
      return this.isPlanning ? this.$i18n.t('hunts.planning') : ''
    }
  },

  methods: {
    openHunt () {
      this.$router.push({
        name: 'GeneralTab',
        params: {
          huntId: this.hunt.id
        }
      })
    }
  }
}
</script>

<style scoped>
.planning {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5px;
  background-color: #ffc048;
}
</style>
